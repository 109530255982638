var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('v-main',{staticClass:"container-prosker"},[_c('v-row',{staticClass:"prosker-container border-radius-medium background_contrast pb-16"},[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('h2',{staticClass:"secondary-prosk font-weight-bold text-center mt-16"},[_vm._v(_vm._s(_vm.$t('nextLevel')))]),_c('p',{staticClass:"text-center main_text_color--text mb-8"},[_vm._v(" "+_vm._s(_vm.$t('subscribeAndGrowYourBusiness'))+" ")])])],1),_c('v-container',[_c('div',{class:_vm.$vuetify.breakpoint.mobile ? 'mx-8' : 'mx-16'},[(_vm.loading)?_c('v-row',{staticClass:"mx-16"},_vm._l((2),function(index){return _c('v-col',{key:index,attrs:{"cols":"6"}},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line,\n                             list-item-two-line, actions"}},'v-skeleton-loader',_vm.skeletonAttrs,false))],1)}),1):_c('v-row',_vm._l((this.subscriptionPlans),function(item,i){return _c('v-col',{key:i,staticClass:"pa- d-flex align-stretch",attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"pricing-card my-0 pt-3 pb-5 px-5 border-radius-medium w-100",class:{
                            'title_highlight_color': item.popular,
                            // 'ml-0 ml-md-5 mt-5 mt-md-0': !item.popular,
                          },attrs:{"elevation":item.popular ? 0 : 2}},[_c('v-card-text',[_c('h4',{staticClass:"text-center font-weight-bold text-h4",class:{
                                  'text-white': item.popular,
                                  'main_text_color--text': !item.popular
                                }},[_vm._v(_vm._s(item.name))]),(item.name === 'Premium')?_c('h5',{staticClass:"text-overline text-center text-white"},[_vm._v("( "+_vm._s(_vm.$t('freeTrial'))+" ) ")]):_vm._e(),(item.name !== 'Premium')?_c('h5',{staticClass:"text-overline text-center"},[_vm._v(_vm._s(_vm.$t('simple_start')))]):_vm._e(),_c('div',{staticClass:"button text-center w-100"},[_c('div',{staticClass:"d-flex align-center w-100 justify-content-center mb-4"},[_c('span',{staticClass:"display-1 font-weight-bold",class:{
                                        'text-white': item.popular,
                                        'main_text_color--text': !item.popular
                                      }},[_c('money-format',{attrs:{"value":item.cost,"locale":_vm.$i18n.locale,"currency-code":_vm.currencyCode,"supplemental-precision":0,"hide-subunits":true}})],1),_c('span',{staticClass:"font-weight-bold",class:{
                                  'text-white': item.popular,
                                  'main_text_color--text': !item.popular
                                }},[_vm._v("/"+_vm._s(_vm.$t("month")))])]),_c('div',{staticClass:"text-center"},[(_vm.$options.SUBSCRIPTIONS_ENABLED)?_c('general-button',{attrs:{"action":_vm.sendEmailOrLogin,"loading":false,"message":_vm.$t('startCTA'),"size":"medium","cssClasses":"mb-2 w-100 secondary--text","white":!!item.popular}}):_vm._e()],1)]),_c('v-divider'),_c('h4',{staticClass:"text-left font-weight-bold",class:{
                                  'text-white': item.popular,
                                  'main_text_color--text': !item.popular
                                }},[_vm._v(_vm._s(_vm.$t('includes')))]),(item.popular)?_c('v-col',{staticClass:"p-0",attrs:{"offset":"0","offset-sm":"1","cols":"12","sm":"10"}},_vm._l((_vm.itemsPremium),function(item,i){return _c('v-list-item',{key:i,staticClass:"p-0"},[_c('img',{attrs:{"src":require("@/assets/images/page-img/Check-green-purple.svg")}}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mt-1 ml-2 space-wrap text-white"},[_vm._v(_vm._s(_vm.$t(item.text)))])],1)],1)}),1):_c('v-col',{staticClass:"p-0 align-content-center",attrs:{"offset":"0","offset-sm":"1","cols":"12","sm":"10"}},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i},[_c('img',{attrs:{"src":require("@/assets/images/page-img/Check-green-black.svg")}}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mt-1 ml-2 space-wrap main_text_color--text"},[_vm._v(_vm._s(_vm.$t(item.text)))])],1)],1)}),1)],1)],1)],1)}),1)],1)])],1),_c('faq-section',{staticClass:"suscription-faqs"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }